import React, { useState } from 'react'
import { PageTemplate } from '../templates/page'
import { list, total } from 'cart-localstorage'
import { loadStripe } from '@stripe/stripe-js'
import SiteSettings from '../data/site-settings.json'
import Button from '../components/shortcodes/Button'
import CheckoutPageStyles from '../styles/CheckoutPage.module.sass'

const pageProps = {
  title: "Checkout",
  subtitle: "Pay quickly and securely for your slatwall panels and accessories!",
  pageIdentifier: "checkout",
  pageClass: "hide-cart",
  showCTA: false,
  showContactForm: false,
}

const PaymentPage = () => {
  // Define states
  const [cart] = useState( 
    typeof window !== 'undefined' 
    ? list()
    : null
  )
  const [deliveryType, setDeliveryType] = useState('Collection')
  const [deliveryCharge, setDeliveryCharge] = useState(0)

  // Prevent Gatsby from prerendering this page
  if (typeof window == 'undefined') {
    return false
  }

  // Calculate totals
  let amountSubtotal = total().toFixed(2)
  let amountVat = (amountSubtotal * 0.23).toFixed(2)
  let amountTotal = (+amountSubtotal + +deliveryCharge + +amountVat).toFixed(2)

  // Send user to Stripe
  async function processForm(event) {
    event.preventDefault()

    let data = {}
    data['cart'] = cart
    data['total'] = amountTotal
    data['vat'] = amountVat

    // Only add delivery charge if there is any
    if (deliveryCharge > 0) {
      data['delivery_charge'] = deliveryCharge
    }

    let fields = event.target.querySelectorAll(
      '.field:not(.is-hidden) input, .field:not(.is-hidden) select'
    )

    for (let field of fields) {
      data[field.name] = field.value
    }

    const response = await fetch('/.netlify/functions/stripe-checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => res.json())

    const stripe = await loadStripe(response.publishableKey)

    const { error } = await stripe.redirectToCheckout({
      sessionId: response.sessionId,
    })

    if (error) {
      console.error(error)
    }
  }

  if (cart && cart.length) {
    return (
      <PageTemplate 
          title={pageProps.title}
          subtitle={pageProps.subtitle}
          pageIdentifier={pageProps.pageIdentifier}
          pageClass={pageProps.pageClass}
          showCTA={pageProps.showCTA}
          showContactForm={pageProps.showContactForm}
      >
        <form
          className={'columns is-multiline pay-form ' + (CheckoutPageStyles.form || '')}
          onSubmit={event => processForm(event)}
        >
          <div className="field column is-half">
            <label htmlFor="name" className="label">
              Full Name
            </label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="name"
                required
              />
            </div>
          </div>
          <div className="field column is-half">
            <label htmlFor="phone" className="label">
              Phone No.
            </label>
            <div className="control">
              <input
                className="input"
                type="tel"
                name="phone"
                pattern="[0-9 +]*"
                required
              />
            </div>
          </div>
          <div className="field column is-half">
            <label htmlFor="company" className="label">
              Company
            </label>
            <div className="control">
              <input className="input" type="text" name="company" placeholder="" />
            </div>
          </div>
          <div className="field column is-half">
            <label htmlFor="email" className="label">
              Email address
            </label>
            <div className="control">
              <input
                className="input"
                type="email"
                name="email"
                required
              />
            </div>
          </div>
          <div className="field column is-full">
            <hr />
          </div>
          <div className="field column is-half">
            <label htmlFor="address" className="label">
              Full Address
            </label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="address"
                required
              />
            </div>
          </div>
          <div className="field column is-half">
            <label htmlFor="city" className="label">
              City or Town
            </label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="city"
                required
              />
            </div>
          </div>
          <div className="field column is-half">
            <label htmlFor="county" className="label">
              County
            </label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="county"
                required
              />
            </div>
          </div>
          <div className="field column is-half">
            <label htmlFor="eircode" className="label">
              Eircode
            </label>
            <div className="control">
              <input className="input" type="text" name="eircode" required />
            </div>
          </div>
          <div className="column is-three-fifths is-offset-two-fifths">
            <div className={`notification is-warning is-light ${CheckoutPageStyles.totals || ''}`}>
              <div className="field">
                <div className="control">
                  <div className="select" required>
                    <select // eslint-disable-line jsx-a11y/no-onchange
                      defaultValue={deliveryType}
                      onChange={event => { 
                          setDeliveryType(event.target.value)
                          setDeliveryCharge(event.target[event.target.selectedIndex].getAttribute('data-price'))
                        } 
                      }
                      name="delivery_type"
                    >
                      {
                        Object.keys(SiteSettings.deliveryCharges).map(item => {
                          return (
                            <option 
                              key={item}
                              value={item}
                              data-price={SiteSettings.deliveryCharges[item]} 
                            >
                              {`${item}${SiteSettings.deliveryCharges[item] > 0 ? ' - \u20AC' + SiteSettings.deliveryCharges[item] : ''}`}
                            </option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>
              <ul className={CheckoutPageStyles.prices}>
                <li>Subtotal: &euro;{Number(amountSubtotal).toFixed(2)}</li>
                <li>VAT: &euro;{Number(amountVat).toFixed(2)}</li>
                {deliveryCharge > 0 && <li>{deliveryType}: &euro;{Number(deliveryCharge).toFixed(2)}</li>}
                <li><strong>Total: &euro;{Number(amountTotal).toFixed(2)}</strong></li>
              </ul>

              <button className={`button is-secondary column is-full ${CheckoutPageStyles.submit || ''}`}>
                Pay Now
              </button>
            </div>
          </div>
          
        </form>
      </PageTemplate>
    )
  }
  else {
    return (
      <PageTemplate 
        title={pageProps.title}
        subtitle={pageProps.subtitle}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        showCTA={pageProps.showCTA}
        showContactForm={pageProps.showContactForm}
      >
        <div className="has-text-centered">
          <div className="notification has-text-left">
            You can't proceed with the <strong>Checkout</strong> because your cart is empty.
          </div>
          <Button url="/" text="Shop Now" />
        </div>
      </PageTemplate>
    )
  }
}

export default PaymentPage
